import dayjs from 'dayjs';

export function isEmail(text = '') {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(text));
}

export const dateFormat = (
  dateString: string | Date,
  emptyString?: string,
  customFormat?: string
) => {
  if (!dateString) {
    return emptyString ?? '-';
  }

  return dayjs(dateString).format(customFormat || 'DD MMM YYYY');
};

export function concatenateItemsWithLimit(items: string[]): string {
  const maxItemsToShow = 3;

  // Concatenate the first maxItemsToShow items:
  const limitedItems = items.slice(0, maxItemsToShow).join(', ');

  // Check if there are more items to indicate:
  const remainingItems = items.length - maxItemsToShow;
  if (remainingItems > 0) {
    return `${limitedItems}, and ${remainingItems} more`;
  } else {
    return limitedItems;
  }
}

export function addNumberSuffix(n: number): string {
  return `${n}${
    ['st', 'nd', 'rd'][(((((n < 0 ? -n : n) + 90) % 100) - 10) % 10) - 1] ||
    'th'
  }`;
}

export const excessStringArray = (arr: string[], limit: number): string[] => {
  if (arr.length > limit) {
    return [...arr.slice(0, limit), `+${arr.length - limit} more`];
  }

  return arr;
}

export const formatCurrency = (val: string | number, decimal?: number, delimiter: '.' | ',' = ',') => {
  let number = val;
  const decimalDelimiter = delimiter === '.' ? ',' : '.';

  if (!number) return '0';
  if (typeof number === 'string') {
    number = parseFloat(number);
    if (Number.isNaN(number)) return '0';
  }

  if (decimal !== null && decimal !== undefined && typeof decimal === 'number')
    number = number.toFixed(decimal);

  number = number.toString().replace('.', decimalDelimiter);
  const numberSections = number.toString().split(decimalDelimiter);
  numberSections[0] = numberSections[0]
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${delimiter ?? ','}`);

  return numberSections.join(decimalDelimiter);
};
