/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import Image from 'components/image';

import ListItemIcon from 'public/images/landing/list_item_icon.svg';
import PlayStoreIcon from 'public/images/download-playstore.svg';
import LogoAppStore from 'public/images/logo/logo-app-store.png';
import PhotoMan1 from 'public/images/landing2/photo/1.png';
import PhotoMan3 from 'public/images/landing2/photo/3.png';
import SeafarerActive from 'public/images/landing2/icon_seafarer.svg';
import SeafarerNotActive from 'public/images/landing2/icon_seafarer-grey.svg';
import ShipActive from 'public/images/landing2/icon_offshore-company.svg';
import ShipNotActive from 'public/images/landing2/icon_offshore-company-grey.svg';
import Link from 'next/link';
import { PlayStoreLink } from 'utils/constants';

const FeaturesSection = () => {
  const router = useRouter();

  const [currentSection, setCurrentSection] = useState<'seafarer' | 'company'>(
    'seafarer'
  );

  useEffect(() => {
    const hash = router.asPath.split('#')?.[1];
    if (hash === 'seafarer' || hash === 'company') {
      setCurrentSection(hash);
    }
  }, [router.asPath]);

  return (
    <section className="pt-64 -mt-32 relative" id="seafarer">
      <div id="company" className="absolute top-0" />
      <div className="bg-white px-16 py-24 pb-42 ipad:px-96">
        <div className="flex gap-24 border-b border-primary">
          <div
            className={`flex w-1/2 gap-4 md:gap-8 cursor-pointer ${
              currentSection === 'seafarer' && 'border-b-2 border-primary'
            }`}
            onClick={() => setCurrentSection('seafarer')}
          >
            <div className="flex items-center max-w-40 md:max-w-full">
              <Image
                src={
                  currentSection === 'seafarer'
                    ? SeafarerActive
                    : SeafarerNotActive
                }
                height={64}
                width={64}
                alt="illustration"
              />
            </div>
            <div className="flex flex-col justify-center">
              <p
                className={`italic text-small1 md:text-regular3 ${
                  currentSection === 'seafarer'
                    ? 'text-primary'
                    : 'text-grey-dark'
                }`}
              >
                Solutions for
              </p>
              <p
                className={`text-xlarge3 ipad:text-title3 font-medium ${
                  currentSection === 'seafarer'
                    ? 'text-primary'
                    : 'text-grey-dark'
                }`}
              >
                Seafarers
              </p>
            </div>
          </div>

          <div
            className={`flex w-1/2 gap-8 cursor-pointer ${
              currentSection === 'company' && 'border-b-2 border-primary'
            }`}
            onClick={() => setCurrentSection('company')}
          >
            <div className="flex items-center max-w-40 md:max-w-full">
              <Image
                src={currentSection === 'company' ? ShipActive : ShipNotActive}
                height={64}
                width={64}
                alt="illustration"
              />
            </div>
            <div className="flex flex-col justify-center">
              <p
                className={`italic text-small1 md:text-regular3 ${
                  currentSection === 'company'
                    ? 'text-primary'
                    : 'text-grey-dark'
                }`}
              >
                Solutions for
              </p>
              <p
                className={`text-xlarge3 ipad:text-title3 font-medium ${
                  currentSection === 'company'
                    ? 'text-primary'
                    : 'text-grey-dark'
                }`}
              >
                Company
              </p>
            </div>
          </div>
        </div>

        <div className="pt-24 ipad:pt-40">
          {currentSection === 'seafarer' ? (
            <>
              <div className="flex flex-col gap-16 mb-16 ipad:mb-40 ipad:flex-row">
                <div className="flex flex-col gap-8 bg-content p-16 rounded-xl ipad:p-24 ipad:w-1/3">
                  <p className="text-primary text-regular3 font-semibold ipad:text-xxlarge3">
                    Discover a trustworthy job portal in your fingertips
                  </p>

                  <div className="border-t border-primary my-8" />

                  <div className="flex items-start gap-12 mb-4">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Browse job openings from trustworthy companies.
                    </p>
                  </div>
                  <div className="flex items-start gap-12">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Submit and track the status of your job applications in
                      real-time.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col gap-8 bg-content p-16 rounded-xl ipad:p-24 ipad:w-1/3">
                  <p className="text-primary text-regular3 font-semibold ipad:text-xxlarge3">
                    Simplified Maritime
                    <br className='hidden md:block' />
                    <span className='ml-4 md:ml-0 font-semibold'>Career Management</span>
                  </p>

                  <div className="border-t border-primary my-8" />

                  <div className="flex items-start gap-12">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter mb-4">
                      Store your documents securely in one place with OnBoard’s
                      secure storage system.
                    </p>
                  </div>
                  <div className="flex items-start gap-12">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Get notifications for expiring documents.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col gap-8 bg-content p-16 rounded-xl ipad:p-24 ipad:w-1/3">
                  <p className="text-primary text-regular3 font-semibold ipad:text-xxlarge3">
                    Join the
                    <br className='hidden md:block' />
                    <span className='ml-4 md:ml-0 font-semibold'>OnBoard community</span>
                  </p>

                  <div className="border-t border-primary my-8" />

                  <div className="flex items-start gap-12">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter mb-4">
                      Be in the network of professional seafarers and find
                      verified job openings.
                    </p>
                  </div>
                  <div className="flex items-start gap-12">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Stay informed with relevant industry insights.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-16 ipad:flex-row">
                <div className="border bg-grey-lighter border-primary rounded-r-xl rounded-tl-xl flex gap-16 items-start p-16">
                  <div className="flex justify-center items-center w-40 md:w-auto shrink-0">
                    <Image
                      src={PhotoMan1}
                      height={100}
                      width={100}
                      alt="profile-photo"
                      className="rounded-full"
                    />
                  </div>
                  <div className="flex flex-col justify-center gap-20">
                    <p className="italic text-primary text-small3 md:text-large3">
                      &quot;This app helps me to organize my documents, make
                      sure required certificates are still valid and reminds me
                      when certificates are about to expire.&quot;
                    </p>
                    <p className="text-primary font-semibold text-small3 md:text-regular3">
                      AA, 3rd Engineer{' '}
                    </p>
                  </div>
                </div>

                <div className="flex justify-center gap-20 rounded-lg bg-primary p-20 flex-row ipad:flex-col desktop:flex-row ipad:p-40 ipad:w-1/3 shrink-0 items-center">
                  <a href={PlayStoreLink} target='_blank' className='inline-flex grow-0 md:shrink-0'>
                    <Image
                      src={PlayStoreIcon}
                      alt="download"
                      height={45}
                      width={140}
                    />
                  </a>
                  <a href='https://apps.apple.com/id/app/onboard-crew-app/id6504788993' target='_blank' className='inline-flex grow-0 md:shrink-0'>
                    <Image
                      src={LogoAppStore}
                      alt="download"
                      height={45}
                      width={140}
                    />
                  </a>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-16 mb-16 ipad:mb-40 ipad:flex-row">
                <div className="flex flex-col gap-8 bg-content p-16 rounded-xl ipad:p-24 ipad:w-1/3">
                  <p className="text-primary text-regular3 font-semibold ipad:text-xxlarge3">
                    Comprehensive Seafarer
                    <br className='hidden md:block' />
                    <span className='ml-4 md:ml-0 font-semibold'>Database</span>
                  </p>

                  <div className="border-t border-primary my-8" />

                  <div className="flex items-start gap-12">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Centralize and streamlines the recruitment process.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col gap-8 bg-content p-16 rounded-xl ipad:p-24 ipad:w-1/3">
                  <p className="text-primary text-regular3 font-semibold ipad:text-xxlarge3">
                    Industry-Specific Job
                    <br className='hidden md:block' />
                    <span className='ml-4 md:ml-0 font-semibold'>Matching Algorithm</span>
                  </p>

                  <div className="border-t border-primary my-8" />

                  <div className="flex items-start gap-12">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Reduces search frictions and shortens lead times.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col gap-8 bg-content p-16 rounded-xl ipad:p-24 ipad:w-1/3">
                  <p className="text-primary text-regular3 font-semibold ipad:text-xxlarge3">
                    Multilayered Tech-Enabled
                    <br className='hidden md:block' />
                    <span className='ml-4 md:ml-0 font-semibold'>Verification</span>
                  </p>

                  <div className="border-t border-primary my-8" />

                  <div className="flex items-start gap-12">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Ensures the quality of seafarers.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-16 ipad:flex-row">
                <div className="border border-primary-light bg-grey-lighter rounded-r-lg rounded-tl-lg flex gap-16 p-16 ipad:w-2/3 ipad:order-2">
                  <div className="w-full ipad:w-[200px] flex justify-center items-center">
                    <Image
                      src={PhotoMan3}
                      height={100}
                      width={100}
                      alt="profile-photo"
                      className="rounded-full"
                    />
                  </div>
                  <div className="flex flex-col justify-center gap-20">
                    <p className="italic text-primary text-large3 font-medium">
                      &quot;As a whole, this is a really great application and
                      platform. Seafarers will find this very helpful because of
                      services like job hunting and seafarer document
                      management. You get to control all that through one
                      app.&quot;
                    </p>
                    <p className="text-primary font-semibold">K, 3rd Officer</p>
                  </div>
                </div>

                <div className="flex flex-col gap-16 rounded-lg bg-primary p-20 ipad:p-40 ipad:w-1/3 ipad:order-1">
                  <Link href="/register">
                    <button className="bg-white w-full text-primary font-semibold text-large3 py-12 px-24 border-none rounded-lg">
                      Sign up
                    </button>
                  </Link>

                  <p className="text-center text-content">
                    Already have an account?{' '}
                    <Link href="/login">
                      <p className="font-semibold text-content underline">
                        Login here!
                      </p>
                    </Link>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
