/* eslint-disable @next/next/no-img-element */
import { useMemo } from 'react';
import { NextPageWithLayout } from 'next';

import Image from 'components/image';

import LandingPageLayout from 'components/layout/landing/LandingPageLayout';
import nonAuth from 'utils/nonAuth';

import ProfileSetupIcon from 'public/images/landing2/profile-setup.svg';
import JobApplicationIcon from 'public/images/landing2/job-application.svg';
import CommunityIcon from 'public/images/landing2/icon_community.svg';
import PhotoMan2 from 'public/images/landing2/photo/2.png';
import Link from 'next/link';
import { PlayStoreLink } from 'utils/constants';
import { useQuery } from 'react-query';
import axios from 'axios';
import FeaturesSection from './FeaturesSection';
import PublicJobList from './PublicJobList';

const Home: NextPageWithLayout = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['getPartners'],
    queryFn: () =>
      axios.get('https://directus.onboardcrewapp.com/items/partners'),
  });

  const partners = useMemo(() => {
    if (!isLoading) {
      return data?.data?.data ?? null;
    }
    return null;
  }, [data, isLoading]);

  return (
    <>
      <section
        className="min-h-screen relative flex items-center bg-primary bg-no-repeat bg-cover bg-center px-16 ipad:bg-right ipad:px-96"
        style={{ backgroundImage: 'url("/images/landing/hero.png")' }}
      >
        <div className="text-left ipad:w-2/3">
          <h1 className="text-title2 font-semibold text-content mb-16 ipad:text-title1">
            Empowering Seafarers, <br />
            Revolutionizing Maritime Recruitment
          </h1>
          <p className="text-regular3 text-content mb-8 ipad:text-xxlarge3">
            Your expert maritime career partner. Clear guidance, hassle-free
            applications, and fast jobs matching.
          </p>
          {/* <p className="text-regular3 text-content ipad:text-regular2">
            We navigate the tides of change, seamlessly matching skilled
            seafarers with diverse opportunities worldwide. Embrace the pinnacle
            of crewing expertise, adapting to the needs of the modern maritime
            world.
          </p> */}
        </div>
      </section>

      <section
        className="bg-primary px-16 pb-64 md:pb-100 ipad:px-96 pt-64 -mt-64"
        id="about"
      >
        <div>
          <p className="italic text-regular2 text-content mb-16 ipad:text-title3">
            About Us
          </p>
          <div className="flex flex-col gap-16 ipad:flex-row">
            <p className="text-xxlarge2 font-semibold text-content ipad:w-1/2 ipad:text-title2 ipad:leading-10">
              We are a maritime recruitment portal reimagined by the community, for the
              community.
            </p>
            <p className="text-regular3 md:text-xlarge3 text-content ipad:w-1/2">
              We connect seafarers with companies seamlessly, fostering trust
              and collaboration.
            </p>
          </div>
        </div>

        <div className="border-t border-grey my-32 md:my-64" />

        <div className="mb-40">
          <p className="italic text-content text-regular2 mb-24 ipad:text-title3 ipad:mb-32">
            All-in-One Jobs Matching Services
          </p>
          <div className="flex flex-col gap-20 ipad:flex-row">
            <div className="bg-content rounded-lg p-16 ipad:w-1/3">
              <Image
                src={ProfileSetupIcon}
                height={36}
                width={36}
                alt="illustration"
              />
              <p className="text-primary text-regular3 font-medium">
                Create and organize seafarer profiles for recruitment.
              </p>
            </div>
            <div className="bg-content rounded-lg p-16 ipad:w-1/3">
              <Image
                src={JobApplicationIcon}
                height={36}
                width={36}
                alt="illustration"
              />
              <p className="text-primary text-regular3 font-medium">
                Apply and manage job applications.
              </p>
            </div>
            <div className="bg-content rounded-lg p-16 ipad:w-1/3">
              <Image
                src={CommunityIcon}
                height={36}
                width={36}
                alt="illustration"
              />
              <p className="text-primary text-regular3 font-medium">
                Connect with the community, build a reputation, and get industry
                updates.
              </p>
            </div>
          </div>
        </div>

        <div className="border border-primary-lighter rounded-r-xl rounded-tl-xl flex gap-16 items-start p-16">
          <div className="flex justify-center items-center w-40 md:w-auto shrink-0">
            <Image
              src={PhotoMan2}
              height={100}
              width={100}
              alt="profile-photo"
              className="rounded-full"
            />
          </div>
          <div>
            <p className="italic text-content text-small3 md:text-large3 mb-24">
              &quot;The OnBoard application is easy to use. Hoping the OnBoard
              app can continue to grow so that seafarers can expand their
              network through this app.&quot;
            </p>
            <p className="text-content font-semibold text-small3 md:text-regular3">IP, Chief Officer</p>
          </div>
        </div>
      </section>

      <div className="wave-container" />

      <FeaturesSection />

      <PublicJobList />

      {/* <div className="wave-container wave-grey" /> */}

      <section className="bg-white px-16 py-32 pb-60 ipad:px-96 my-12">
        <p className="text-title3 text-primary font-medium text-center ipad:text-title2 mb-12 ipad:mb-20">
          Trusted by well-known organizations across the globe
        </p>
        <div className="pt-32 flex flex-wrap justify-center">
          {partners?.map((item) => (
            <div
              className="basis-1/3 md:basis-1/5 cursor-pointer flex items-center justify-center"
              key={item.name}
            >
              <div
                className="max-w-[150px] px-20 md:px-20 py-20 md:py-12"
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                }}
              >
                <a href={item.website} target="_blank">
                  <img
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      height: '100%',
                    }}
                    src={`https://directus.onboardcrewapp.com/assets/${item.logo}`}
                    alt={item.name}
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>

      <div className="wave-container wave-primary" />

      <section className="bg-primary px-16 py-60 ipad:px-96 flex flex-col items-center gap-24 ipad:gap-40">
        <p className="italic text-white font-medium text-xxlarge3 md:text-title3">
          Ready to get started?
        </p>
        <div className="flex gap-20">
          <Link href={PlayStoreLink} passHref>
            <a className="border-2 border-white rounded-2xl py-12 px-24 text-white text-center">
              Register as Seafarer
            </a>
          </Link>
          <Link href="/register" passHref>
            <a className="border-2 border-white rounded-2xl py-12 px-24 text-white text-center">
              Register as Company
            </a>
          </Link>
        </div>
      </section>
    </>
  );
};

Home.getLayout = (page, props) => (
  <LandingPageLayout title="Home" {...props}>
    {page}
  </LandingPageLayout>
);

export default nonAuth(Home, false);
