import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'components/image';
import APIv2 from 'utils/api/v2';
import { dateFormat, formatCurrency } from 'utils/stringHelper';
import EllipseSvg from 'public/images/ellipse_2.svg';
import { FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';

const PublicJobList = () => {
  const router = useRouter();
  const [swiper, setSwiper] = useState<any>();

  const { data, isLoading } = useQuery({
    queryKey: ['getJobs'],
    queryFn: () =>
      APIv2.getPublicJobs(null, {
        withCredentials: true,
      }),
  });

  const jobsList = useMemo(() => {
    if (!isLoading) {
      const result = data?.data?.data;
      return result;
    }
  }, [isLoading, data]);

  return (
    <section
      className={clsx(
        'px-0 ipad:px-96 flex justify-center',
        isLoading && jobsList?.length === 0 && 'hidden'
      )}
    >
      <div className="p-24 pr-0 pl-0 md:pl-24 bg-primary md:rounded-2xl gap-24 w-full overflow-hidden">
        <div className="flex flex-col md:flex-row w-full md:mb-24 md:gap-48 gap-24">
          <div className="md:w-4/12 flex flex-col justify-between">
            <div className='px-24 md:px-0'>
              <p className="text-content font-bold text-xxlarge2 md:text-title2 mb-24 max-w-full md:max-w-[320px]">
                Explore a variety of your new opportunities.
              </p>
              <p className="text-warning-lightest font-medium text-large2">
                {jobsList?.length} jobs are available.
              </p>
            </div>

            <div className="hidden md:block mb-16">
              <Link href={'/jobs'}>
                <a className="text-white text-large3">
                  View All Jobs <i className="ri-arrow-right-fill"></i>
                </a>
              </Link>
            </div>
          </div>

          <div className="md:w-8/12">
            <div className="flex md:hidden justify-end gap-20 mr-24 mb-24">
              <i
                className="ri-arrow-left-line text-white cursor-pointer"
                onClick={() => swiper.slidePrev()}
              ></i>
              <i
                className="ri-arrow-right-line text-white cursor-pointer"
                onClick={() => swiper.slideNext()}
              ></i>
            </div>
            <Swiper
              freeMode
              spaceBetween={24}
              modules={[FreeMode]} 
              slidesPerView={'auto'}
              onSwiper={setSwiper}
              className="w-full !px-24 md:!px-0"
            >
              {jobsList?.slice(0, 4)?.map((x, index) => (
                <SwiperSlide
                  className={
                    index === 3
                      ? 'md:!w-[60%] md:mr-56 min-w-[480px] max-w-[480px] md:min-w-0'
                      : 'md:!w-[60%] min-w-[480px] max-w-[480px] md:min-w-0'
                  }
                  key={index}
                >
                  <div
                    className="p-24 bg-white rounded-xl cursor-pointer"
                    onClick={() => router.push(`/job/${x.id}?notCompany=true`)}
                  >
                    <div className="flex gap-16 mb-16">
                      <img
                        src={x.company?.logo}
                        className="w-64 h-64 object-contain rounded-xl border border-grey-light"
                        alt="company logo"
                      />

                      <div className="flex flex-col gap-8">
                        <p className="text-small3 text-ink-lighter">
                          {x.company?.name}
                        </p>
                        <p className="text-large1 text-ink-light font-semibold">
                          {x.rank}
                        </p>
                        <div className="flex text-small3 gap-8 flex-wrap">
                          <div className="flex gap-2">
                            <p className="text-ink-lighter font-normal">
                              Apply before{' '}
                              <span className="text-ink-lighter font-medium">
                                {dateFormat(x?.expiry_date)}
                              </span>
                            </p>
                          </div>
                          <Image
                            src={EllipseSvg}
                            width={4}
                            height={4}
                            alt="logo2"
                            className="h-48 w-auto"
                          />
                          <p className="text-ink-lighter font-normal ">
                            Contract
                          </p>
                          <Image
                            src={EllipseSvg}
                            width={4}
                            height={4}
                            alt="logo2"
                            className="h-48 w-auto"
                          />
                          <p className="text-ink-lighter text-small3 font-normal ">
                            {x?.contract_length} months
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="p-16 bg-primary-lightest/50 rounded-lg flex flex-col gap-8">
                      {/* <p className="font-semibold text-primary">{x.salary}</p> */}
                      <div className="flex flex-row gap-8 text-small3">
                        <i className="text-primary font-bold ri-money-dollar-circle-line"></i>
                        <p className="text-primary font-bold">
                          {x?.salary ? (
                            <>
                              {x?.currency} {formatCurrency(x?.salary)}
                            </>
                          ) : (
                            <>Confidential</>
                          )}
                        </p>
                      </div>
                      <div className="flex flex-row gap-8 text-small3">
                        <i className="text-primary font-bold ri-map-pin-line"></i>
                        <p className="text-ink-lighter">
                          Min. {x?.min_exp} years of experience
                        </p>
                      </div>
                      <div className="flex flex-row gap-8 text-small3">
                        <i className="text-primary font-bold ri-map-pin-line"></i>
                        <p className="text-ink-lighter">
                          Working area:{' '}
                          <span className="font-bold">{x?.working_area}</span>
                        </p>
                      </div>
                      <div className="flex flex-row gap-8 text-small3">
                        <i className="text-primary font-bold ri-ship-line"></i>
                        <p className="text-ink-lighter">
                          Vessel type:{' '}
                          <span className="font-bold">{x?.vessel_type}</span>
                        </p>
                      </div>
                      <div className="flex flex-row gap-8 text-small3">
                        <i className="text-primary font-bold ri-flag-line"></i>
                        <p className="text-ink-lighter">
                          Vessel flag:{' '}
                          <span className="font-bold">{x?.vessel_flag}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="md:hidden flex justify-center py-12 mt-16">
              <Link href={'/jobs'}>
                <a className="text-white text-large3">
                  View All Jobs <i className="ri-arrow-right-fill"></i>
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden md:flex justify-end gap-10 mr-24">
          <i
            className="ri-arrow-left-line text-white cursor-pointer"
            onClick={() => swiper.slidePrev()}
          ></i>
          <i
            className="ri-arrow-right-line text-white cursor-pointer"
            onClick={() => swiper.slideNext()}
          ></i>
        </div>
      </div>
    </section>
  );
};

export default PublicJobList;
